<template>
	<div>
		<el-dialog title="题目编辑" :visible.sync="dialogShow" :close-on-click-modal="false" @close="closeDialog"
			width="800px" top="60px">
			<el-form :model="formData" ref="ruleForm" label-width="120px">
				<el-form-item label="所属课程：" prop="qst_title">
					<el-select v-model="formData.course_id" size="small"
						style="width: 150px; margin-right: 20px;">
						<el-option label="请选择课程" :value="0"></el-option>
						<el-option v-for="(item,idx) in courses" :key="idx" :label="item.course_name" :value="item.id">
						</el-option>
					
					</el-select>
				</el-form-item>
				
				
				<el-form-item label="题干内容：" prop="qst_title">
					<el-input v-model.trim="formData.qst_title" placeholder="请输入题目标题" style="width: 100%" />
				</el-form-item>
				<el-form-item label="题干图片：" prop="qst_title">
					<el-upload  style="display: inline-block; margin-right: 20px;" action=""
						:http-request="uploadOss" :on-success="uploadTMFiles" :show-file-list="false"
						name="image">
						
						<el-button  v-if="!formData.qst_files||formData.qst_files.length==0"  title="传图" size="small"
							type="primary" icon="el-icon-picture-outline-round" 
							circle=""></el-button>
						<img v-viewer  v-for="(file,i) in formData.qst_files" :key="i"  :src="file.src"  
							style="width: 32px; height: 32px; vertical-align: middle; margin-right: 20px;">
								
							
						
					
					</el-upload>
				</el-form-item>
				<el-form-item label="题目描述：" prop="qst_desc">
					<el-input type="textarea" :rows="2" v-model.trim="formData.qst_desc" placeholder="请输入题目描述"
						style="width: 100%" />
				</el-form-item>
				<el-form-item label="题目类型：" prop="course_code">
					<el-select v-model="formData.qst_type" placeholder="请选择" style="width: 200px; margin-right: 10px;">
						<el-option label="单选题" value="radio"></el-option>
						<el-option label="多选题" value="checkbox"></el-option>
						<el-option label="判断题" value="panduan"></el-option>
						<el-option label="填空题" value="tiankong"></el-option>
						<el-option label="主观题" value="zhuguan"></el-option>

					</el-select>
				</el-form-item>
				
				<el-form-item label="此题分数：" prop="qst_desc">
					<el-input v-model.trim="formData.score" placeholder="请输入分数(数字)" style="width: 25%" />
				</el-form-item>
				
				<el-form-item label="答案解析：" v-if="false">
					<el-input type="textarea" placeholder="答案解析" :rows="2" v-model.trim="formData.qst_title"
						style="width: 100%" />
				</el-form-item>
				<fieldset style="position: relative;" v-if="formData.qst_type=='radio'||formData.qst_type=='checkbox'||formData.qst_type=='panduan'">
					<legend>选项</legend>
					<div @click="addXX"
						style="position: absolute;top: -20px; right: 10px; font-size: 20px; background-color: #FFFFFF;">
						<i class="el-icon-circle-plus" style="color: cornflowerblue; cursor: pointer;"></i>
					</div>
					<el-row :gutter="20" v-for="(xx,idx) in tmxx" :key="idx" style="margin-bottom: 20px;">
						<el-col :span="1" style="line-height: 40px;">
							{{rowNum[idx]}}
						</el-col>
						<el-col :span="15">
							<el-input v-model.trim="xx.xxtitle" placeholder="请输入题目标题" style="width: 100%" />
						</el-col>
						<el-col :span="6" style="line-height: 40px;">
							<div style="display: inline-block;">
								<el-upload v-if="!xx.files||xx.files.length==0" style="display: inline-block; margin-right: 20px;" action=""
									:http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false"
									name="image">
									
									<el-button    title="传图" size="small"
										type="primary" icon="el-icon-picture-outline-round" @click="setRowIndx(idx)"
										circle=""></el-button>
										
										
									

								</el-upload>
								
								<img v-viewer  v-for="(file,i) in xx.files" :key="i"  :src="file.src"  @click="setRowIndx(idx)"
									style="width: 32px; height: 32px; vertical-align: middle; margin-right: 20px;">
									
									
									
							</div>
							<el-checkbox v-model="xx.iscorrect">此项正确</el-checkbox>
						</el-col>
						<el-col :span="2" style="line-height: 40px; font-size: 20px;">
							
							<el-popconfirm  confirmButtonText='确定'
								cancelButtonText='取消' icon="el-icon-info" iconColor="red"
								@confirm="removeXX(idx)" placement="left" title="是否确定删除？">
								<a slot="reference" size="small"
									style=" cursor: pointer;">
									<i  class="el-icon-remove" style="color: orangered; cursor: pointer;"></i>
									</a>
								
							</el-popconfirm>
							
							
							
						</el-col>
					</el-row>
				</fieldset>



			</el-form>
			<el-row style="margin-top: 20px;">
				<el-col :span="24" style="text-align: right;">
					<el-button size="small" type="default" icon="el-icon-close" @click="isShowModal = false">取消
					</el-button>
					<el-button size="small" type="primary" icon="el-icon-check" @click="submitForm('ruleForm')">保存
					</el-button>
				</el-col>
			</el-row>

		</el-dialog>

	</div>
</template>

<script>
	export default {
		props: ['tmid','courseid','courses'],
		data() {
			return {
				dialogShow: true,
				formData: {
					id:0,
					course_id:0,
					qst_title: '',
					qst_desc: '',
					qst_type: '',
					qst_files:[],
					score:""
					

				},
				tmxx: [],
				xxinfo: {
					id: 0,
					tmid: 0,
					xxtitle: '',
					iscorrect: 0,
					sort: 99,
					files: []
				},
				rowidx:-1,
				rowNum:['A','B','C','D','E','F','G','H','I','J','K'],
				
			}
		},


		mounted() {
			if (!this.tmid) {
				this.tmxx = [Object.assign({}, this.xxinfo)]
				this.formData.course_id = this.courseid;

			}else{
				this.getData()
			}
			
			
		},
		methods: {
			getData(){
				this.$http.post("/api/tk_timu_one",{tmid:this.tmid}).then(res=>{
					if(res.data.qst_files){
						res.data.qst_files = JSON.parse(res.data.qst_files)
					}
					
					
					
					this.formData = res.data;
					if(res.data.tmxx){
						
						for(let item of res.data.tmxx){
							item.files =  JSON.parse(item.files )
							item.iscorrect?item.iscorrect = true:item.iscorrect=false
						}
						
						
						this.tmxx = res.data.tmxx
					}else{
						this.tmxx = [Object.assign({}, this.xxinfo)]
					}
				})
			},
			closeDialog() {
				this.$parent.showTM = false
			},
			setRowIndx(idx) {
				this.rowidx = idx
			},
			uploadFiles(e){
				this.tmxx[this.rowidx].files=[e]
			},
			uploadTMFiles(e){
				this.formData.qst_files=[e]
			},
			addXX(){
				this.tmxx.push(Object.assign({}, this.xxinfo))
			},
			removeXX(idx){
				let xx = this.tmxx[idx];
				if(xx.id>0){
					this.$http.post("/api/tk_timuxx_delete",xx).then(res=>{
						this.$message.success("删除成功")
						
						
					})
				}
				this.tmxx.splice(idx,1)
			},
			submitForm(){
				if(!this.formData.course_id){
					this.$message.error("请选择课程")
					return
				}
				if(!this.formData.qst_type){
					this.$message.error("请选择题目类型")
					return
				}
				if(!this.formData.score){
					this.$message.error("请输入分数")
					return
				}
				
				let data = JSON.parse(JSON.stringify(this.formData))
				if(data.qst_type=="radio"||data.qst_type=="checkbox"||data.qst_type=="panduan"){
					data.tmxx =  JSON.parse(JSON.stringify(this.tmxx))
				}
				
				
				
				this.$http.post("/api/tk_timu_edit",data).then(res=>{
					this.$message.success("保存成功")
					this.$parent.getList();
					
				})
				
			}
		},
	}
</script>

<style>
</style>
