<template>
	<div class="page pageContainer" style="height: 100%;">
		<el-row>
			<span style="font-size: 14px;">题目标题：</span>
			<el-input v-model="searchForm.keyword" size="small" placeholder="请输入查询关键字"
				style="max-width: 200px;margin-right: 10px; margin-left: 10px;" />
			<span style="font-size: 14px;">课程：</span>
			<el-select @change="setCourseName" v-model="searchForm.course_id" size="small"
				style="width: 150px; margin-right: 20px;">
				<el-option label="全部" :value="0"></el-option>
				<el-option v-for="(item,idx) in CourseList" :key="idx" :label="item.course_name" :value="item.id">
				</el-option>

			</el-select>


			<el-select v-model="searchForm.qst_type" size="small" style="width: 150px; margin-right: 20px;">
				<el-option label="全部" value=""></el-option>
				<el-option label="单选题" value="radio"></el-option>
				<el-option label="多选题" value="checkbox"></el-option>
				<el-option label="判断题" value="panduan"></el-option>
				<el-option label="填空题" value="tiankong"></el-option>
				<el-option label="主观题" value="zhuguan"></el-option>
			</el-select>

			<el-button size="small" icon="el-icon-search" @click="getList" plain type="primary">查询</el-button>
			<el-button size="small" icon="el-icon-upload" @click="showExport=true" plain type="primary">导入题目</el-button>



			<el-link type="primary" href="/xls/tm_temp.xlsx" target="_blank" style="margin-left: 20px;">下载题目模板</el-link>

			<el-button style="margin-left: 20px;" size="small" type="warning" icon="el-icon-delete" @click="deleteTK"
				plain>删除题目</el-button>

		</el-row>

		<el-dialog title="导入题目" :visible.sync="showExport" :close-on-click-modal="false" width="500px" top="200px">
			<el-select @change="setCourseName" v-model="searchForm.course_id" size="small"
				style="width: 200px; margin-right: 20px;">
				<el-option label="请选择课程" :value="0"></el-option>
				<el-option v-for="(item,idx) in CourseList" :key="idx" :label="item.course_name" :value="item.id">
				</el-option>

			</el-select>
			<div style="display: inline-block;">
				<el-upload style="display: inline-block; margin-right: 20px; margin-left: 10px" action=""
					:http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
				</el-upload>
			</div>

		</el-dialog>


		<div class="cbox" style="padding: 20px 0;">

			<el-tabs v-model="activeName" type="border-card" style="width: 100%">
				<el-tab-pane :label="activeName" :name="activeName">
					<el-table stripe :data="tableData" size="mini" border stripe @sort-change="changeTableSort">

						<el-table-column prop="id" width="80" label="序号">
							<template slot-scope="scope">
								{{scope.$index+1}}

							</template>
						</el-table-column>
						<el-table-column prop="course_name" label="所属课程" width="100"></el-table-column>
						<el-table-column prop="qst_title" label="题目标题" min-width="100"></el-table-column>


						<el-table-column prop="qst_type" label="题目类型" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.qst_type=='radio'">单选题</span>
								<span v-if="scope.row.qst_type=='checkbox'">多选题</span>
								<span v-if="scope.row.qst_type=='panduan'">判断题</span>
								<span v-if="scope.row.qst_type=='tiankong'">填空题</span>
								<span v-if="scope.row.qst_type=='zhuguan'">主观题</span>


							</template>
						</el-table-column>
						<el-table-column prop="score" label="分数" width="100" sortable="custom"></el-table-column>
						<el-table-column prop="succ_count" label="正确次数" width="100" sortable="custom"></el-table-column>
						<el-table-column prop="fail_count" label="错误次数" width="100" sortable="custom"></el-table-column>
						<el-table-column fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
									icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)" placement="left"
									title="是否确定删除？">
									<a slot="reference" size="small"
										style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
								</el-popconfirm>
								<el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>

							</template>
						</el-table-column>


					</el-table>
					<el-pagination @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next, jumper">
					</el-pagination>
				</el-tab-pane>
				<el-tab-pane disabled>
					<div slot="label">
						<el-button size="mini" icon="el-icon-plus" @click="formAdd" type="primary">新增题目</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>


		</div>
		<tm v-if="showTM" :tmid="tmid" :courseid="this.searchForm.course_id" :courses="CourseList"></tm>



	</div>
</template>

<script>
	import tm from "./tm.vue"
	export default {
		components: {
			tm
		},
		data() {
			return {
				searchForm: {
					course_id: 0,
					qst_type: "",
					keyword: "",
					ktype: "qst_title"
				},
				activeName: "全部课程",
				showExport: false,
				tableData: [],
				CourseList: [],
				page: {
					count: 1,
					current_page: 1,
					per_page: 20,
					total_page: 1
				},
				tmid: 0,
				showTM: false
			}
		},


		mounted() {
			this.getCourseList()
			this.getList()
		},
		methods: {
			getCourseList() {
				let _this = this
				this.$http.post("/api/t_sch_course_list", {
					pagesize: 100
				}).then(res => {
					this.CourseList = res.data.data
				})

				this.$http.post("/api/get_teacher_course").then(res => {
					if (res.data.length > 0) {
						this.searchForm.course_id = res.data[0].id
					}
				})

			},
			setCourseName() {
				if (this.searchForm.course_id > 0) {
					for (let item of this.CourseList) {
						if (item.id == this.searchForm.course_id) {
							this.activeName = item.course_name
						}
					}
				} else {
					this.activeName = "全部课程"
				}
				this.getList()
			},
			getList() {
				this.closeTm()
				this.searchForm.page = this.page.current_page;
				//http://mmnet.oicp.vip
				this.$http.post("/api/tk_timu_list", this.searchForm).then(res => {
					this.tableData = res.data.data;
					this.page = res.data.page
				})

			},
			handlePageChange(e) {
				this.page.current_page = e
				this.getList()
			},
			formAdd() {
				this.tmid = 0;
				this.showTM = true
			},
			closeTm() {
				this.tmid = 0;
				this.showTM = false
			},
			handleModify(item) {
				this.tmid = item.id;
				this.showTM = true
			},
			handleDel() {},
			changeTableSort(column) {
				this.searchForm.orderby = column.prop + " " + (column.order == "ascending" ? 'asc' : 'desc')
				this.getList()
			},
			uploadFiles(e) {

				this.$http.post("/api/import_tm", {
					url: e.src,
					course_id: this.searchForm.course_id
				}).then(res => {
					this.$message.success("导入成功")

					this.getList();
					this.showExport = false


				})
			},

			deleteTK() {
				if(!this.searchForm.course_id){
					this.$alert("请在左侧选择一个课程！")
					return
				}
				this.$confirm('确认删除该课程所有题目?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/tk_tiku_delete", {
						course_id: this.searchForm.course_id
					}).then(res => {
						this.$message.success("清空题目成功")
					
						this.getList();
						
					
					
					})
				}).catch(() => {
					
				});
			}




		}
	}
</script>

<style>
</style>
